<template>
  <div class="invitation">
    <div class="invitation-head">
      <div class="invitation-title-con">
        <img class="invitation-title" src="~assets/images/index/title.png" alt="">
      </div>
      <div class="invitation-text">10万+医生职业成长加速器</div>
      <img class="invitation-people" src="~assets/images/index/people.png" alt="">
    </div>
    <div class="invitation-body">
      <div class="join-suantan">
        <ul class="join-ul">
          <li class="join-item vertical-center">
            <img class="join-icon" src="~assets/images/index/icon.png" alt="">
            <span class="join-text">师兄/师姐学习工作经验分享</span>
          </li>
          <li class="join-item vertical-center">
            <img class="join-icon" src="~assets/images/index/icon.png" alt="">
            <span class="join-text">文献分享学习活动共同提高</span>
          </li>
          <li class="join-item vertical-center">
            <img class="join-icon" src="~assets/images/index/icon.png" alt="">
            <span class="join-text">微博超话学习打卡督促学习</span>
          </li>
        </ul>
        <div class="join-group vertical-center">
          <div class="group-img align-center">
            <img class="qrcode" :src="$confStatic.ossCdn + '/images/join-association-code.png?time='+new Date().getTime()" @click="codeImg()" alt="">
          </div>
          <img class="group-tip" src="~assets/images/index/instructions.png" alt="">
          <div class="group-text">邀请你加入酸谈社群完成任务解锁积分商城扫码加入社群</div>
        </div>
      </div>
      <div class="join-reject flex" v-if="userInfo.status_team && (userInfo.status_team.operate_status == 4 || userInfo.status_team.operate_status == 7)">
        <img class="reject-icon" src="~assets/images/index/tip.png" alt="">
        <div class="reject-text text-justify-t">驳回原因：{{userInfo.status_team.reason}}</div>
      </div>
      <div class="apply-join" @click="joinFun" v-if="userInfo.status_team && (userInfo.status_team.operate_status == 4 || userInfo.status_team.operate_status == 7 || userInfo.status_team.operate_status == 6) && invite_code_status">
        申请加入
      </div>
    </div>
  </div>
</template>

<script>
import { ImagePreview } from 'vant';
export default {
  name: "JoinCommunity",
  data(){
    return{
      invite_code_status: false
    }
  },
  created() {
    let _this = this;
    if(localStorage.getItem('invite_code')){
      this.invite_code_status = true
    }
    // 判断是否有战队和邀请码的
    if(this.userInfo && this.userInfo.status_team && this.userInfo.status_team.operate_status == 1 && localStorage.getItem('invite_code')){
      this.$user.userInvites(function (status){
        if(status){
          setTimeout(()=>{
            _this.linkTeam()
          },1500)
        }else {
          if(localStorage.getItem('invite_code')){
            _this.invite_code_status = true
          }else {
            _this.invite_code_status = false
          }
        }
      });
    }
    this.linkTeam()
  },
  methods: {
  // 二维码预览
    codeImg(){
      ImagePreview({
        images: [this.$confStatic.ossCdn + '/images/join-association-code.png?time='+new Date().getTime()]
      });
    },
    // 申请加入
    joinFun(){
      let _this = this;
      this.$user.userInvites(function (status){
        if(status){
          setTimeout(()=>{
            _this.linkTeam()
          },1500)
        }else {
          if(localStorage.getItem('invite_code')){
            _this.invite_code_status = true
          }else {
            _this.invite_code_status = false
          }
        }
      });
    },
    // 路由跳转
    linkTeam(){
      if(this.userInfo && this.userInfo.status_team){
        // 判断有战队时进入首页
        if(this.userInfo.status_team.operate_status == 3){
          this.$router.replace('/')
        }else if(this.userInfo.status_team.operate_status == 2){
          this.$router.replace('/audit')
        }
      }
    }

  },
  watch:{
    userInfo:{
      deep: true,
      handle(val,old){
        if(val.status_team.operate_status == 3){
          this.$router.replace('/')
        }
        return val
      }
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/user/join-community/invitation.scss";
</style>
